<script>
import { getSupplierCustomersSearchTools } from '@/graphql/customer/queries.graphql';

import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  query() {
    return {
      query: getSupplierCustomersSearchTools,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
