<template>
  <customer-mutation>
    <template v-slot="{ loading, setCustomerTags }">
      <div v-if="!loading" class="flex flex-wrap">
        <el-select
          :value="customerTagList"
          default-first-option
          allow-create
          filterable
          collapse-tags
          multiple
          placeholder="Click here to add tags"
          @change="onChange(setCustomerTags, $event)"
        >
          <el-option
            v-for="option in unionTags"
            :key="`tag-${option}`"
            :label="option"
            :value="option"
          >
          </el-option>
        </el-select>
      </div>
      <div v-else class="flex justify-center">
        <loading-button />
      </div>
    </template>
  </customer-mutation>
</template>

<script>
import { union } from 'lodash';
import { CustomerMutation } from '@/components/Mutations';
import LoadingButton from '@/components/Core/Loading/LoadingButton';

export default {
  components: {
    CustomerMutation,
    LoadingButton
  },
  props: {
    allTags: {
      type: Array,
      default: () => []
    },
    customer: {
      type: Object,
      required: true
    }
  },
  computed: {
    customerTagList() {
      return this.customer.tags.map(tag => tag.name);
    },
    unionTags() {
      return union(
        this.allTags.map(tag => tag.name),
        this.customerTagList
      );
    }
  },
  methods: {
    onChange(setCustomerTags, tags) {
      setCustomerTags(this.customer.id, tags);
    }
  }
};
</script>
