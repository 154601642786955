<template>
  <customer-mutation>
    <template v-slot="{ loading, setInvoiceTerms }">
      <custom-el-select
        v-if="$can('pub_manage_customer')"
        :value="customer.terms"
        placeholder="Select terms"
        class="w-full"
        select-class="w-full"
        singular
        hide-icons
        value-by-object
        label-key="text"
        :is-loading="loading"
        :options="options"
        @change="setInvoiceTerms(customer.id, $event.id)"
      />
      <div v-else>
        {{ customer.terms.text }}
      </div>
    </template>
  </customer-mutation>
</template>

<script>
import { CustomerMutation } from '@/components/Mutations';
import CustomElSelect from '@/components/Custom/CustomElSelect';

export default {
  components: {
    CustomElSelect,
    CustomerMutation
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    customer: {
      type: Object,
      required: true
    }
  }
};
</script>
