<template>
  <section class="border-top pt-6 mt-6">
    <section class="mb-10">
      <el-form
        ref="noteForm"
        :rules="rules"
        :model="noteForm"
        :disabled="!$can('manage_customer_notes')"
      >
        <el-form-item prop="newNote">
          <el-input
            v-model="noteForm.newNote"
            class="mb-4"
            type="textarea"
            :rows="3"
            placeholder="Add a new note"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <el-button
        v-if="!isLoading"
        class="w-48 button-blue"
        :disabled="!$can('manage_customer_notes')"
        @click="addNote"
      >
        Save Note
      </el-button>
      <loading-button v-else class="w-48" />
    </section>
    <div v-for="note in notes" :key="`note-${note.id}`" class="mb-5">
      <note :note="note" />
    </div>
  </section>
</template>

<script>
import { momentTz } from '@/utils/date';

import { LoadingButton } from '@/components/Core/Loading';
import Note from '@/components/Core/Note';

export default {
  components: {
    Note,
    LoadingButton
  },
  props: {
    isLoading: Boolean,
    notes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      noteForm: {
        newNote: ''
      },
      rules: {
        newNote: [
          {
            required: true,
            message: "Notes can't be blank",
            trigger: 'change'
          },
          {
            message: 'Notes must be less than 1024 characters',
            max: 1024,
            trigger: 'change'
          }
        ]
      }
    };
  },
  methods: {
    addNote() {
      this.$emit('submit', [{ text: this.noteForm.newNote }]);
      this.$refs.noteForm.resetFields();
    },
    createdDate(note) {
      return momentTz(note.created_at).format('MM/DD/YYYY h:mm:ss a');
    }
  }
};
</script>
