var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('customer-rep-mutation',{attrs:{"show-error-message":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var isSavingCustomerRep = ref.isSaving;
      var addCustomerRep = ref.addCustomerRep;
      var removeCustomerRep = ref.removeCustomerRep;
return [_c('order-rep-mutation',{attrs:{"show-error-message":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var isSavingOrderRep = ref.isSaving;
          var addOrderRep = ref.addOrderRep;
          var removeOrderRep = ref.removeOrderRep;
return [_c('div',[_c('div',{staticClass:"flex justify-between"},[(_vm.reps.length > 0)?_c('div',[_c('ul',{staticClass:"rep-list"},_vm._l((_vm.reps),function(rep){return _c('li',{key:("rep-" + (rep.id))},[(rep.name)?_c('span',[_vm._v(_vm._s(rep.name))]):_c('span',[_vm._v(_vm._s(rep.email))])])}),0)]):_vm._e(),_c('div',[_c('tooltip-icon',{attrs:{"reference-class":"text-blue cursor-pointer hover:text-dark-blue","is-disabled":!_vm.$can('pub_assign_or_edit_reps'),"icon":_vm.pencilIcon},on:{"click":function($event){_vm.showRepDialog = true}},scopedSlots:_vm._u([{key:"after",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.reps.length > 0 ? 'Edit' : 'Assign Rep')+" ")])]},proxy:true}],null,true)})],1)]),(_vm.showRepDialog)?_c('confirm-dialog',{attrs:{"id":"rep-picker-dialog","title":_vm.dialogTitle,"disabled":true,"cancel-text":"Close","width":"40%"},on:{"close":function($event){_vm.showRepDialog = false}}},[_c('rep-picker',{attrs:{"available-reps":_vm.availableReps,"current-reps":_vm.reps,"is-saving-id":_vm.isSavingId},on:{"add":function($event){return _vm.onAdd($event, addOrderRep, addCustomerRep)},"remove":function($event){return _vm.onRemove($event, removeOrderRep, removeCustomerRep)}}})],1):_vm._e()],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }