<template>
  <form-toggle
    :title="title"
    :sub-title="subTitle"
    :is-disabled="!$can('pub_manage_customer')"
    :is-loading="isLoading"
    :has-changes="hasChanges"
    :show-submit="!hideSubmit"
    :hide-close="hideClose"
    :is-editing="isEditing"
    @update:is-editing="$emit('update:is-editing', $event)"
    @submit="$emit('submit', addressForm)"
  >
    <template slot="view">
      <template v-if="address">
        <div v-if="address.name">{{ address.name }}</div>
        <div>{{ address.address }}</div>
        <div v-if="address.city || address.state || address.zip_code">
          {{ address.city }},
          {{ address.state }}
          {{ address.zip_code }}
        </div>
      </template>
    </template>

    <template slot="edit">
      <el-form
        ref="infoForm"
        label-position="top"
        :disabled="isLoading"
        :rules="rules"
        :model="addressForm"
      >
        <div>
          <custom-el-form-item
            class="w-full"
            label-class="font-bold"
            label="Addressee Name"
            prop="name"
            :is-loading="isLoading"
          >
            <el-input
              v-model="addressForm.name"
              placeholder="Name"
              @input="onInput"
            />
          </custom-el-form-item>
        </div>
        <div class="flex flex-row">
          <custom-el-form-item
            class="w-1/2 mr-3"
            label-class="font-bold"
            label="Address"
            prop="address"
            :is-loading="isLoading"
          >
            <el-input
              v-model="addressForm.address"
              placeholder="Address"
              @input="onInput"
            />
          </custom-el-form-item>
          <custom-el-form-item
            class="w-1/2"
            label-class="font-bold"
            label="City"
            prop="city"
            :is-loading="isLoading"
          >
            <el-input
              v-model="addressForm.city"
              placeholder="City"
              @input="onInput"
            />
          </custom-el-form-item>
        </div>
        <div class="flex flex-row mt-4">
          <custom-el-form-item
            class="w-1/2 mr-3"
            label-class="font-bold"
            label="State"
            prop="state"
            :is-loading="isLoading"
          >
            <select-state
              v-model="addressForm.state"
              placeholder="State"
              @input="onInput"
            />
          </custom-el-form-item>
          <custom-el-form-item
            class="w-1/2"
            label-class="font-bold"
            label="Zip"
            prop="zip_code"
            :is-loading="isLoading"
          >
            <el-input
              v-model="addressForm.zip_code"
              placeholder="Zip"
              @input="onInput"
            />
          </custom-el-form-item>
        </div>
      </el-form>
    </template>
  </form-toggle>
</template>

<script>
import {
  cancel as closeIcon,
  pencil as pencilIcon
} from '@/vendor/icons/index';

import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import SelectState from '@/components/Core/SelectState';
import FormToggle from '../../Core/Form/FormToggle';
import { isEqual } from 'lodash';

const defaultRules = { required: true, trigger: 'blur' };

export default {
  components: {
    CustomElFormItem,
    FormToggle,
    SelectState
  },
  props: {
    title: {
      type: String,
      default: 'Address'
    },
    subTitle: {
      type: String,
      default: ''
    },
    address: {
      type: Object,
      default: () => ({})
    },
    isEditing: Boolean,
    isLoading: Boolean,
    hideClose: Boolean,
    hideSubmit: Boolean
  },
  data() {
    return {
      defaultRules: { required: true, trigger: 'blur' },
      addressForm: this.getDefaults(),
      rules: {
        address: [{ ...defaultRules, message: 'Please input a Address' }],
        city: [{ ...defaultRules, message: 'Please input a City' }],
        state: [{ ...defaultRules, message: 'Please select a State' }],
        zip_code: [{ ...defaultRules, message: 'Please input a Zip Code' }]
      },

      // Icons
      closeIcon,
      pencilIcon
    };
  },
  computed: {
    hasChanges() {
      return !isEqual(this.getDefaults(), this.addressForm);
    }
  },
  watch: {
    buyer() {
      if (this.$refs.infoForm) {
        this.$refs.infoForm.clearValidate();
      }
      this.addressForm = this.getDefaults();
    }
  },
  methods: {
    getDefaults() {
      return {
        address: this.address && this.address.address,
        city: this.address && this.address.city,
        state: this.address && this.address.state,
        zip_code: this.address && this.address.zip_code
      };
    },
    onInput() {
      this.$emit('update:address', this.addressForm);
    }
  }
};
</script>
