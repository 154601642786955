<template>
  <form-toggle
    title="Company"
    :is-disabled="!$can('pub_manage_customer')"
    :is-loading="isLoading"
    :has-changes="hasChanges"
    show-submit
    :is-editing="isEditing"
    @update:is-editing="$emit('update:is-editing', $event)"
    @submit="$emit('submit', infoForm)"
  >
    <template slot="view">
      <div>{{ buyer.buyer_company }}</div>
    </template>

    <template slot="edit">
      <el-form
        ref="infoForm"
        label-position="top"
        :disabled="isLoading"
        :rules="rules"
        :model="infoForm"
      >
        <custom-el-form-item
          class="w-full mt-4"
          label-class="font-bold"
          label="Name"
          prop="buyer_company"
        >
          <el-input v-model="infoForm.buyer_company" placeholder="Name" />
        </custom-el-form-item>
      </el-form>
    </template>
  </form-toggle>
</template>

<script>
import {
  cancel as closeIcon,
  pencil as pencilIcon
} from '@/vendor/icons/index';

import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import FormToggle from '../../Core/Form/FormToggle';
import { isEqual } from 'lodash';

const defaultRules = { required: true, trigger: 'blur' };

export default {
  components: {
    CustomElFormItem,
    FormToggle
  },
  props: {
    buyer: {
      type: Object,
      required: true
    },
    isEditing: Boolean,
    isLoading: Boolean
  },
  data() {
    return {
      defaultRules: { required: true, trigger: 'blur' },
      infoForm: this.getDefaults(),
      rules: {
        buyer_company: [{ ...defaultRules, message: 'Please input a Name' }]
      },

      // Icons
      closeIcon,
      pencilIcon
    };
  },
  computed: {
    hasChanges() {
      return !isEqual(this.getDefaults(), this.infoForm);
    }
  },
  watch: {
    buyer() {
      if (this.$refs.infoForm) {
        this.$refs.infoForm.clearValidate();
      }
      this.infoForm = this.getDefaults();
    }
  },
  methods: {
    getDefaults() {
      return {
        buyer_company: this.buyer.buyer_company
      };
    }
  }
};
</script>
