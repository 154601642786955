<template>
  <form-toggle
    :title="title"
    :is-loading="isLoading"
    :is-disabled="!$can('pub_manage_customer')"
    :is-editing="isEditing"
    @update:is-editing="$emit('update:is-editing', $event)"
  >
    <template slot="view">
      <div v-if="contact">
        <div v-if="contact.name">{{ contact.name }}</div>
        <div v-if="contact.email">{{ contact.email }}</div>
        <div v-if="contact.phone">{{ contact.phone | phone }}</div>
      </div>
    </template>
    <edit-contact-form
      slot="edit"
      :contact="contact"
      :is-loading="isLoading"
      show-submit
      @submit="$emit('submit', $event)"
    />
  </form-toggle>
</template>

<script>
import EditContactForm from '../../Core/Settings/EditContactForm';
import FormToggle from '../../Core/Form/FormToggle';
import { pencil as pencilIcon } from '@/vendor/icons/index';

export default {
  components: {
    EditContactForm,
    FormToggle
  },
  props: {
    contact: {
      type: Object,
      default: null
    },
    isEditing: Boolean,
    isLoading: Boolean,
    title: {
      type: String,
      default: 'Primary Contact'
    }
  },
  data() {
    return {
      pencilIcon
    };
  }
};
</script>
