<template>
  <customer-mutation>
    <template slot-scope="{ loading, approvePaymentMethod }">
      <div v-if="!loading">
        <el-tooltip :disabled="canEditInvoiceSettings" effect="light">
          <template #content>
            <div class="w-40">
              <a
                href="https://intercom.help/flytedesk/articles/3102562-assigning-team-roles"
                target="_blank"
              >
                Learn more about user permission roles
              </a>
            </div>
          </template>
          <div>
            <el-checkbox
              :disabled="!canEditInvoiceSettings"
              :value="!customer.can_pay_by_alternate_method"
              @change="changePaymentMethod(approvePaymentMethod)"
            />
          </div>
        </el-tooltip>
      </div>
      <loading-button v-else />
    </template>
  </customer-mutation>
</template>

<script>
import { PaymentMethodName } from '@/constants';
import { find } from 'lodash';

import LoadingButton from '@/components/Core/Loading/LoadingButton';
import { CustomerMutation } from '@/components/Mutations';

export default {
  components: {
    CustomerMutation,
    LoadingButton
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  computed: {
    paymentMethods() {
      return this.$store.getters['auth/supplier'].paymentMethods;
    },
    canEditInvoiceSettings() {
      return this.$can('pub_change_invoice_settings');
    },
    invoicePaymentMethod() {
      return find(this.paymentMethods, { name: PaymentMethodName.INVOICE });
    },
    creditCardPaymentMethod() {
      return find(this.paymentMethods, { name: PaymentMethodName.CREDIT });
    }
  },
  methods: {
    changePaymentMethod(approvePaymentMethod) {
      if (this.customer.can_pay_by_alternate_method) {
        approvePaymentMethod(
          this.customer.buyer.id,
          this.creditCardPaymentMethod.id
        );
      } else {
        approvePaymentMethod(
          this.customer.buyer.id,
          this.invoicePaymentMethod.id
        );
      }
    }
  }
};
</script>
