<template>
  <customer-mutation>
    <template v-slot="{ isSaving, createCustomer }">
      <edit-dialog
        :is-saving="isSaving"
        title="Create a New Customer"
        show-dialog
        save-text="Create Account"
        cancel-text="Cancel"
        primary-class="button-green"
        width="50%"
        @close="$emit('close')"
        @save="onSubmit(createCustomer)"
      >
        <h2>Company Information</h2>
        <el-form
          ref="companyNameForm"
          :rules="rules"
          label-position="top"
          label-width="100px"
          :model="companyNameForm"
        >
          <custom-el-form-item
            class="w-full mt-4"
            label-class="font-bold"
            label="Company Name"
            prop="buyer_company"
            :is-loading="isSaving"
          >
            <el-input
              v-model="companyNameForm.buyer_company"
              placeholder="Name"
              auto-complete="organization"
            />
          </custom-el-form-item>
          <custom-el-form-item
            class="w-full mt-4"
            label-class="font-bold"
            label="Customer Type"
            prop="customer_type"
          >
            <el-select
              v-model="companyNameForm.customer_type"
              placeholder="Select Customer Type"
              class="w-full"
              select-class="w-full"
              singular
              hide-icons
            >
              <el-option
                v-for="customerType in CustomerTypes"
                :key="'customer-type-' + customerType.value"
                :value="customerType.value"
                :label="customerType.label"
              />
            </el-select>
          </custom-el-form-item>
          <edit-address
            title="Primary Address"
            sub-title="Please enter your primary mailing address"
            class="mt-5 border-top pt-5"
            :address.sync="addressForm"
            :is-loading="isSaving"
            is-editing
            hide-submit
            hide-close
          />
        </el-form>
        <h2 class="border-top pt-6 mt-6">Primary Contact</h2>
        <user-info-form
          ref="primaryContactForm"
          v-model="primaryContactForm"
          :is-loading="isSaving"
        />
        <h2 class="border-top pt-6 mt-6">Billing Contact</h2>
        <user-info-form
          ref="billingContactForm"
          v-model="billingContactForm"
          :is-loading="isSaving"
          @focus="billingContactTouched = true"
        />
      </edit-dialog>
    </template>
  </customer-mutation>
</template>

<script>
import { ContactType, CustomerTypes } from '@/constants';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import { CustomerMutation } from '@/components/Mutations';
import UserInfoForm from '@/components/Core/Form/UserInfoForm';
import EditDialog from '@/components/Core/EditDialog';
import EditAddress from '@/components/Supplier/Customers/EditAddress';

export default {
  components: {
    EditAddress,
    EditDialog,
    CustomElFormItem,
    CustomerMutation,
    UserInfoForm
  },
  data() {
    return {
      CustomerTypes,
      billingContactTouched: false,
      companyNameForm: {
        buyer_company: '',
        customer_type: ''
      },
      addressForm: {
        address: '',
        address2: '',
        city: '',
        state: '',
        zip_code: ''
      },
      primaryContactForm: {},
      billingContactForm: {},
      rules: {
        buyer_company: [
          {
            required: true,
            message: 'Please input company name',
            trigger: 'blur'
          }
        ]
      }
    };
  },

  methods: {
    async createCustomer(createCustomerMutation) {
      const input = {
        buyer: {
          buyer_company: this.companyNameForm.buyer_company,
          primaryAddress: this.addressForm
        },
        customer_type: this.companyNameForm.customer_type,
        contacts: [{ type: ContactType.PRIMARY, ...this.primaryContactForm }]
      };
      if (this.billingContactForm.email) {
        input.contacts.push({
          type: ContactType.BILLING,
          ...this.billingContactForm
        });
      }
      await createCustomerMutation(input);

      this.$message.success('Your Customer was successfully created!');

      this.$emit('close');
    },
    async onSubmit(createCustomerMutation) {
      let isValid = true;
      await this.$refs.companyNameForm.validate(valid => {
        isValid = isValid && valid;
        this.$refs.primaryContactForm.validate(valid => {
          isValid = isValid && valid;
          if (this.billingContactTouched) {
            this.$refs.billingContactForm.validate(valid => {
              isValid = isValid && valid;
            });
          }
          if (isValid) this.createCustomer(createCustomerMutation);
        });
      });
    }
  }
};
</script>
