var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('customer-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var isSaving = ref.isSaving;
    var updateCustomerBuyer = ref.updateCustomerBuyer;
    var updateCustomer = ref.updateCustomer;
    var setCustomerNotes = ref.setCustomerNotes;
    var setCustomerFiles = ref.setCustomerFiles;
return [_c('edit-dialog',{staticClass:"p-0",attrs:{"title":_vm.customer.buyer.buyer_company,"width":"70%"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"flex max-h-70vh overflow-hidden"},[_c('div',{staticClass:"border-right flex flex-col min-w-15 p-8 overflow-y-auto"},[_c('edit-customer-info',{attrs:{"buyer":_vm.customer.buyer,"is-loading":isSaving,"is-editing":_vm.isEditingCustomerInfo},on:{"update:isEditing":function($event){_vm.isEditingCustomerInfo=$event},"update:is-editing":function($event){_vm.isEditingCustomerInfo=$event},"submit":function($event){return _vm.onSubmitEditCustomerInfo(updateCustomerBuyer, $event)}}}),_c('edit-address',{staticClass:"mt-5 border-top pt-5",attrs:{"title":"Primary Address","address":_vm.customer.buyer.primaryAddress,"is-loading":isSaving,"is-editing":_vm.isEditingCustomerPrimaryAddress},on:{"update:isEditing":function($event){_vm.isEditingCustomerPrimaryAddress=$event},"update:is-editing":function($event){_vm.isEditingCustomerPrimaryAddress=$event},"submit":function($event){return _vm.onSubmitEditCustomerPrimaryAddress(updateCustomerBuyer, {
                primaryAddress: $event
              })}}}),_c('edit-address',{staticClass:"mt-5 border-top pt-5",attrs:{"title":"Billing Address","address":_vm.customer.buyer.billingAddress,"is-loading":isSaving,"is-editing":_vm.isEditingCustomerBillingAddress},on:{"update:isEditing":function($event){_vm.isEditingCustomerBillingAddress=$event},"update:is-editing":function($event){_vm.isEditingCustomerBillingAddress=$event},"submit":function($event){return _vm.onSubmitEditCustomerBillingAddress(updateCustomerBuyer, {
                billingAddress: $event
              })}}}),_c('edit-customer-contact',{staticClass:"mt-5 border-top pt-5",attrs:{"contact":_vm.customer.buyerPrimaryContact,"is-loading":isSaving,"is-editing":_vm.isEditingPrimaryContact},on:{"update:isEditing":function($event){_vm.isEditingPrimaryContact=$event},"update:is-editing":function($event){_vm.isEditingPrimaryContact=$event},"submit":function($event){return _vm.onSubmitEditPrimaryContact(updateCustomer, $event)}}}),_c('edit-customer-contact',{staticClass:"mt-5 border-top pt-5",attrs:{"title":"Billing Contact","contact":_vm.customer.buyerBillingContact || {},"is-loading":isSaving,"is-editing":_vm.isEditingBillingContact},on:{"update:isEditing":function($event){_vm.isEditingBillingContact=$event},"update:is-editing":function($event){_vm.isEditingBillingContact=$event},"submit":function($event){return _vm.onSubmitEditBillingContact(updateCustomer, $event)}}})],1),_c('div',{staticClass:"flex flex-1 flex-col p-8 overflow-y-auto"},[_c('div',{staticClass:"reviewer-box flex-grow"},[_c('upload-box',{attrs:{"disabled":_vm.isMutatingFiles || !_vm.$can('pub_edit_company_info'),"file-item":_vm.fileItem,"uploading":_vm.isMutatingFiles,"can-delete":"","enable-preview":""},on:{"update:uploading":function($event){_vm.isMutatingFiles=$event},"complete":function($event){return _vm.updateFiles($event, setCustomerFiles)}},model:{value:(_vm.customer.files),callback:function ($$v) {_vm.$set(_vm.customer, "files", $$v)},expression:"customer.files"}})],1),_c('edit-customer-notes',{attrs:{"notes":_vm.customer.notes,"is-loading":isSaving},on:{"submit":function($event){return setCustomerNotes(_vm.customer.id, $event)}}})],1)]),_c('template',{slot:"footer"},[(isSaving || _vm.isMutatingFiles)?_c('loading-button'):_c('el-button',{staticClass:"button-white",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")])],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }