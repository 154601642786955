<template>
  <div class="flex justify-between items-end">
    <div class="flex items-center">
      <export-button :is-exporting="isExporting" @click="exportCustomers" />
    </div>
  </div>
</template>

<script>
// TODO: Remove this component in favor of a GraphQL export endpoint
import axios from 'axios';
import { download } from '@/utils/helpers';
import { debounce } from 'lodash';

import ExportButton from '@/components/Core/Table/ExportButton';

export default {
  components: {
    ExportButton
  },
  props: {
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isExporting: false
    };
  },

  methods: {
    exportCustomers() {
      this.isExporting = true;
      const params = {
        filter: {
          ...this.filter,
          'buyer.campaign_type_id': 2,
          is_active: true
        },
        supplierId: this.$store.getters['auth/supplierId']
      };

      axios
        .post('/supplier/customers/export', params)
        .then(({ data }) => {
          if (data.success) {
            download(data.csv, data.name);
          }
        })
        .finally(() => {
          this.isExporting = false;
        });
    },
    updateFilter: debounce(function(newFilter) {
      const filter = {
        ...this.filter,
        ...newFilter
      };
      this.$emit('change', filter);
      this.$emit('update:filter', filter);
    }, 500)
  }
};
</script>
