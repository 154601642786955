<template>
  <el-form class="import-customers-form">
    <custom-el-form-item type="columns" label="Upload Customers File">
      <template slot="help">
        The customers file should match
        <a
          href="https://docs.google.com/spreadsheets/d/15hFJMFGH3R66I1rJFRiLuLROo5HvUR3x51GMa0B1j08/edit?usp=sharing"
          target="_blank"
        >
          this template
        </a>
      </template>

      <input type="file" @change="fileChanged($event.target.files)" />
    </custom-el-form-item>
  </el-form>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';

export default {
  components: {
    CustomElFormItem
  },

  data() {
    return {
      form: {
        file: null
      }
    };
  },

  methods: {
    fileChanged(files) {
      if (files.length > 0) {
        this.form.file = files[0];
      } else {
        this.form.file = null;
      }

      this.$emit('input', this.form);
    }
  }
};
</script>
