<template>
  <customer-mutation>
    <template
      v-slot="{
        isSaving,
        updateCustomerBuyer,
        updateCustomer,
        setCustomerNotes,
        setCustomerFiles
      }"
    >
      <edit-dialog
        :title="customer.buyer.buyer_company"
        class="p-0"
        width="70%"
        @close="$emit('close')"
      >
        <div class="flex max-h-70vh overflow-hidden">
          <!-- Left Column -->
          <div class="border-right flex flex-col min-w-15 p-8 overflow-y-auto">
            <edit-customer-info
              :buyer="customer.buyer"
              :is-loading="isSaving"
              :is-editing.sync="isEditingCustomerInfo"
              @submit="onSubmitEditCustomerInfo(updateCustomerBuyer, $event)"
            />
            <edit-address
              title="Primary Address"
              class="mt-5 border-top pt-5"
              :address="customer.buyer.primaryAddress"
              :is-loading="isSaving"
              :is-editing.sync="isEditingCustomerPrimaryAddress"
              @submit="
                onSubmitEditCustomerPrimaryAddress(updateCustomerBuyer, {
                  primaryAddress: $event
                })
              "
            />
            <edit-address
              title="Billing Address"
              class="mt-5 border-top pt-5"
              :address="customer.buyer.billingAddress"
              :is-loading="isSaving"
              :is-editing.sync="isEditingCustomerBillingAddress"
              @submit="
                onSubmitEditCustomerBillingAddress(updateCustomerBuyer, {
                  billingAddress: $event
                })
              "
            />
            <edit-customer-contact
              class="mt-5 border-top pt-5"
              :contact="customer.buyerPrimaryContact"
              :is-loading="isSaving"
              :is-editing.sync="isEditingPrimaryContact"
              @submit="onSubmitEditPrimaryContact(updateCustomer, $event)"
            />
            <edit-customer-contact
              class="mt-5 border-top pt-5"
              title="Billing Contact"
              :contact="customer.buyerBillingContact || {}"
              :is-loading="isSaving"
              :is-editing.sync="isEditingBillingContact"
              @submit="onSubmitEditBillingContact(updateCustomer, $event)"
            />
          </div>
          <!-- Right Column -->
          <div class="flex flex-1 flex-col p-8 overflow-y-auto">
            <div class="reviewer-box flex-grow">
              <upload-box
                v-model="customer.files"
                :disabled="isMutatingFiles || !$can('pub_edit_company_info')"
                :file-item="fileItem"
                :uploading.sync="isMutatingFiles"
                can-delete
                enable-preview
                @complete="updateFiles($event, setCustomerFiles)"
              />
            </div>
            <edit-customer-notes
              :notes="customer.notes"
              :is-loading="isSaving"
              @submit="setCustomerNotes(customer.id, $event)"
            />
          </div>
        </div>
        <template slot="footer">
          <loading-button v-if="isSaving || isMutatingFiles" />
          <el-button v-else class="button-white" @click="$emit('close')">
            Close
          </el-button>
        </template>
      </edit-dialog>
    </template>
  </customer-mutation>
</template>

<script>
import { ContactType } from '@/constants';

import { CustomerMutation } from '@/components/Mutations';
import EditCustomerContact from './EditCustomerContact';
import EditCustomerInfo from './EditCustomerInfo';
import EditCustomerNotes from './EditCustomerNotes';
import { LoadingButton } from '@/components/Core/Loading';
import UploadBox from '@/components/Core/UploadBox';
import EditDialog from '../../Core/EditDialog';
import EditAddress from './EditAddress';

export default {
  components: {
    EditAddress,
    EditDialog,
    CustomerMutation,
    EditCustomerContact,
    EditCustomerInfo,
    EditCustomerNotes,
    LoadingButton,
    UploadBox
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ContactType,

      fileItem: {
        width: '7.5em',
        height: '7.5em',
        class: 'rounded shadow text-lg'
      },
      isEditingCustomerInfo: false,
      isEditingCustomerBillingAddress: false,
      isEditingCustomerPrimaryAddress: false,
      isEditingPrimaryContact: false,
      isEditingBillingContact: false,
      isMutatingFiles: false
    };
  },
  methods: {
    async onSubmitEditCustomerInfo(updateCustomerBuyer, input) {
      await updateCustomerBuyer(this.customer.id, input);
      this.isEditingCustomerInfo = false;
    },
    async onSubmitEditCustomerPrimaryAddress(updateCustomerBuyer, input) {
      await updateCustomerBuyer(this.customer.id, input);
      this.isEditingCustomerPrimaryAddress = false;
    },
    async onSubmitEditCustomerBillingAddress(updateCustomerBuyer, input) {
      await updateCustomerBuyer(this.customer.id, input);
      this.isEditingCustomerBillingAddress = false;
    },
    async onSubmitEditPrimaryContact(updateCustomer, input) {
      await updateCustomer(this.customer, {
        contacts: [{ type: ContactType.PRIMARY, ...input }]
      });

      this.isEditingPrimaryContact = false;
    },
    async onSubmitEditBillingContact(updateCustomer, input) {
      await updateCustomer(this.customer, {
        contacts: [{ type: ContactType.BILLING, ...input }]
      });

      this.isEditingBillingContact = false;
    },
    async updateFiles(files, setCustomerFiles) {
      await setCustomerFiles(
        this.customer.id,
        files.map(file => ({ id: file.id }))
      );

      this.isMutatingFiles = false;
    }
  }
};
</script>
