<template>
  <customer-mutation>
    <template v-slot="{ isSaving, updateCustomer }">
      <el-select
        v-if="$can('pub_manage_customer')"
        :value="customer.type"
        placeholder="Select Customer Type"
        class="w-full"
        select-class="w-full"
        singular
        hide-icons
        :is-loading="isSaving"
        @change="updateCustomer(customer, { type: $event })"
      >
        <el-option
          v-for="customerType in CustomerTypes"
          :key="'customer-type-' + customerType.value"
          :value="customerType.value"
          :label="customerType.label"
        />
      </el-select>
      <div v-else>
        {{ customer.type }}
      </div>
    </template>
  </customer-mutation>
</template>

<script>
import { CustomerMutation } from '@/components/Mutations';
import { CustomerTypes } from '@/constants';

export default {
  components: {
    CustomerMutation
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      CustomerTypes
    };
  }
};
</script>
