var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('supplier-customers-search-tools-query',{on:{"data":function($event){_vm.searchTools = $event.supplier.customersData.searchTools}}}),_c('invoice-terms-query',{on:{"data":function($event){_vm.termOptions = $event}}}),_c('div',{staticClass:"flex flex-row border-top"},[_c('div',{staticClass:"flex-shrink-0"},[_c('collapsible-search-group-sidebar',{attrs:{"search-tools":_vm.searchTools || [],"is-loading":!_vm.searchTools,"filter":_vm.pager.filter},on:{"search":function($event){_vm.pager = Object.assign({}, _vm.pager, $event)}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c('div',{staticClass:"flex flex-1 flex-col p-8 overflow-x-auto min-w-40 border-left"},[_c('filter-toolbar',{staticClass:"items-end",attrs:{"hide-export":"","placeholder":"Search Companies, Reps, etc.","value":_vm.pager.filter},on:{"input":function($event){_vm.pager = Object.assign({}, _vm.pager, {filter: $event})}}},[_c('supplier-customers-toolbar',{attrs:{"filter":_vm.pager.filter}})],1),_c('div',{staticClass:"relative"},[_c('supplier-customers-query',{attrs:{"pager":_vm.pager},on:{"data":_vm.onData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(isLoading),expression:"isLoading"}],staticClass:"el-table-slim mt-6",attrs:{"border":"","data":_vm.customers}},[_c('table-empty-slot',{attrs:{"slot":"empty","is-loading":isLoading,"has-data":!!_vm.customers,"error-message":"There was a problem loading customers. Please try again later."},slot:"empty"},[_vm._v(" You do not have any customers yet! "),_c('a',{on:{"click":function($event){_vm.showCreateCustomerDialog = true}}},[_vm._v(" Create a new customer. ")])]),_c('el-table-column',{attrs:{"align":"center","width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('icon',{staticClass:"icon-xl cursor-pointer text-blue hover:text-dark-blue",attrs:{"icon":_vm.editIcon},on:{"click":function($event){_vm.customerToEdit = row}}})]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"buyer.buyer_company","label":"Company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.buyer.buyer_company)+" ("+_vm._s(row.buyer.id)+") ")])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Rep"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('reps-column',{attrs:{"customer":row,"available-reps":_vm.availableReps}})]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","label":"Default Schedule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('customers-billing-preference-column',{attrs:{"customer":row}})]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","label":"Default Terms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('customers-invoice-terms-column',{attrs:{"customer":row,"options":_vm.termOptions}})]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","label":"Customer Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('customer-type-column',{attrs:{"customer":row}})]}}],null,true)}),(_vm.allowCreditCardPayment)?_c('custom-el-table-column',{attrs:{"align":'center',"width":"115"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('customers-invoice-column',{attrs:{"customer":row}})]}}],null,true)},[_c('div',{staticClass:"whitespace-normal",attrs:{"slot":"header"},slot:"header"},[_c('div',[_vm._v("Require")]),_c('div',[_vm._v(" Credit Card "),_c('el-tooltip',{staticClass:"text-dark-silver",attrs:{"effect":"light"}},[_c('div',{staticClass:"text-base w-100",attrs:{"slot":"content"},slot:"content"},[_c('div',[_vm._v(" Indicates customers are only approved to use a credit card to pay for Ad Shop Orders. ")]),_c('div',{staticClass:"mt-3"},[_vm._v(" If not required, they will be able to pay by Invoice allowing you to process their payments manually. ")]),_c('div',{staticClass:"mt-6 font-bold"},[_vm._v(" IMPORTANT: Your customer will still be able to check out in your Ad Shop without a credit card! You will receive an order that requires approval before becoming active. If you reject the order, the buyer then will be required to enter a credit card at that time or cancel the order. ")])]),_c('icon',{attrs:{"icon":_vm.helpIcon}})],1)],1)])]):_vm._e(),_c('el-table-column',{attrs:{"label":"Tags","width":"228"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('customers-tags-column',{attrs:{"all-tags":_vm.availableTags,"customer":row}})]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","label":"Action","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('customers-actions-column',{attrs:{"customer":row}})]}}],null,true)})],1),(_vm.paginator && _vm.paginator.total > 0)?_c('pagination',{staticClass:"mt-6",attrs:{"info":_vm.paginator,"pager":_vm.pager},on:{"update:pager":function($event){_vm.pager=$event}}}):_vm._e(),(_vm.showCreateCustomerDialog)?_c('create-customer-dialog',{on:{"close":function($event){_vm.showCreateCustomerDialog = false}}}):_vm._e(),(_vm.customerToEdit)?_c('edit-customer-dialog',{attrs:{"customer":_vm.customerToEdit},on:{"close":function($event){_vm.customerToEdit = null}}}):_vm._e()]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }