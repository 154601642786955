<template>
  <customer-mutation @done="onDone" @error="onDone">
    <template v-slot="{ isSaving, archiveCustomer }">
      <el-dialog
        :visible="visible"
        :close-on-click-modal="false"
        :show-close="false"
        :title="`Remove ${customer.buyer.buyer_company}?`"
        width="50%"
        append-to-body
        @update:visible="$event || $emit('close')"
        @close="$emit('close')"
      >
        <div class="text-center text-md">
          Are you sure you’d like to remove this customer?
          <br />
          You will no longer be able to place and manage orders for this
          customer if you remove them.
        </div>
        <template slot="footer">
          <template v-if="!isSaving">
            <el-button class="button" @click="$emit('close')">
              Never Mind
            </el-button>
            <el-button class="button-red" @click="archiveCustomer(customer)">
              Remove Customer
            </el-button>
          </template>
          <loading-button v-else />
        </template>
      </el-dialog>
    </template>
  </customer-mutation>
</template>

<script>
import { CustomerMutation } from '@/components/Mutations';
import { LoadingButton } from '@/components/Core/Loading';

export default {
  components: {
    CustomerMutation,
    LoadingButton
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    loading: Boolean,
    visible: Boolean
  },
  methods: {
    onDone() {
      this.$emit('close');
    }
  }
};
</script>
