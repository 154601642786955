<template>
  <section>
    <!-- Invite Buyer Dialog -->
    <el-dialog
      v-bind="dialogBindings"
      :visible="true"
      :close-on-click-modal="false"
      :show-close="false"
      width="50%"
      append-to-body
      @close="$emit('close')"
    >
      <transition name="fade" mode="out-in">
        <div v-if="!showSubmitWarningDialog" key="invite-buyer-dialog">
          <h4 class="mb-2">Invite Buyer</h4>
          <div class="mb-6">
            Enter the email and name of the buyer you’d like to invite to be a
            user on this buyer account. This will allow them to view ongoing
            campaign statuses, upload creative, and view verifications. Learn
            more
          </div>

          <el-form
            ref="inviteForm"
            label-position="top"
            label-width="100px"
            :model="inviteForm"
            :rules="rules"
          >
            <div class="flex items-end">
              <custom-el-form-item class="flex-1" prop="email">
                <el-input
                  v-model="inviteForm.email"
                  placeholder="Enter Email"
                />
              </custom-el-form-item>
              <custom-el-form-item class="flex-1" prop="name">
                <el-input v-model="inviteForm.name" placeholder="Enter Name" />
              </custom-el-form-item>
              <el-button
                class="button-green h-12 ml-3 min-w-10"
                @click="onInvite"
              >
                Invite
              </el-button>
            </div>
          </el-form>

          <!-- Buyer Team Table -->
          <buyer-users-query :buyer-id="customer.buyer.id">
            <template v-slot="{ isLoading, data }">
              <h4 class="mb-4 mt-8">
                Current team: {{ customer.buyer.buyer_company }}
              </h4>
              <div v-if="!isLoading" class="overflow-y-auto">
                <el-table
                  border
                  class="el-table-slim"
                  :data="data.customer.buyer.teamMembers"
                >
                  <template #empty>
                    <table-empty-slot
                      :is-loading="isLoading"
                      :has-data="!!data.customer.buyer.teamMembers"
                      empty-message="This Buyer doesn't have any team members yet"
                    />
                  </template>
                  <el-table-column prop="name" label="Name">
                    <template v-slot="{ row: user }">
                      <span>{{ user.name || 'Guest User' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="email" label="Email">
                    <template v-slot="{ row: user }">
                      <span>{{ user.email }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <loader-box v-else height="6em" />
            </template>
          </buyer-users-query>
        </div>

        <!-- ###################### The Warning Dialog #################### -->

        <div v-else key="submit-warning-dialog">
          <div>
            This user will recieve an invitation email from flytedesk, as well
            as future email updates about campaign activity. Please double check
            that you’ve entered the correct email:
          </div>
          <h2>{{ inviteForm.email }}</h2>
        </div>
      </transition>

      <template slot="footer">
        <div class="w-full">
          <transition name="fade" mode="out-in">
            <div
              v-if="showSubmitWarningDialog"
              key="submit-warning-footer"
              class="flex w-full"
            >
              <buyer-team-mutation
                class="flex w-full"
                @done="showSubmitWarningDialog = false"
              >
                <template v-slot="{ isSaving, inviteUserToBuyerAccount }">
                  <template v-if="!isSaving">
                    <el-button
                      class="button-white"
                      @click="showSubmitWarningDialog = false"
                    >
                      Never mind
                    </el-button>
                    <el-button
                      class="button-white-red"
                      @click="
                        (inviteForm.email = '') ||
                          (showSubmitWarningDialog = false)
                      "
                    >
                      Change Email
                    </el-button>
                    <el-button
                      class="button-blue"
                      @click="
                        inviteUserToBuyerAccount(
                          customer.buyer.id,
                          inviteForm.email,
                          inviteForm.name
                        )
                      "
                    >
                      Yes, send it!
                    </el-button>
                  </template>
                  <loading-button v-else />
                </template>
              </buyer-team-mutation>
            </div>
            <div v-else key="invite-buyer-footer" class="flex w-full">
              <el-button @click="$emit('close')">
                Close
              </el-button>
            </div>
          </transition>
        </div>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import { validEmail } from '@/utils/filters';

import { BuyerUsersQuery } from '@/components/Queries';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import { LoadingButton } from '@/components/Core/Loading';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import BuyerTeamMutation from '@/components/Mutations/Buyer/BuyerTeamMutation';

export default {
  components: {
    BuyerTeamMutation,
    BuyerUsersQuery,
    CustomElFormItem,
    LoadingButton,
    TableEmptySlot
  },

  props: {
    customer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showSubmitWarningDialog: false,
      inviteForm: {
        email: ''
      },
      rules: {
        email: [{ required: true, validator: validEmail, trigger: 'blur' }]
      }
    };
  },
  computed: {
    dialogBindings() {
      if (this.showSubmitWarningDialog) {
        return {
          title: 'Are you sure?'
        };
      } else {
        return {
          title: 'Invite Buyer'
        };
      }
    }
  },
  methods: {
    onInvite() {
      this.$refs.inviteForm.validate(valid => {
        if (valid) {
          this.showSubmitWarningDialog = true;
        }
      });
    }
  }
};
</script>
