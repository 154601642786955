var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('el-dialog',_vm._b({attrs:{"visible":true,"close-on-click-modal":false,"show-close":false,"width":"50%","append-to-body":""},on:{"close":function($event){return _vm.$emit('close')}}},'el-dialog',_vm.dialogBindings,false),[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.showSubmitWarningDialog)?_c('div',{key:"invite-buyer-dialog"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Invite Buyer")]),_c('div',{staticClass:"mb-6"},[_vm._v(" Enter the email and name of the buyer you’d like to invite to be a user on this buyer account. This will allow them to view ongoing campaign statuses, upload creative, and view verifications. Learn more ")]),_c('el-form',{ref:"inviteForm",attrs:{"label-position":"top","label-width":"100px","model":_vm.inviteForm,"rules":_vm.rules}},[_c('div',{staticClass:"flex items-end"},[_c('custom-el-form-item',{staticClass:"flex-1",attrs:{"prop":"email"}},[_c('el-input',{attrs:{"placeholder":"Enter Email"},model:{value:(_vm.inviteForm.email),callback:function ($$v) {_vm.$set(_vm.inviteForm, "email", $$v)},expression:"inviteForm.email"}})],1),_c('custom-el-form-item',{staticClass:"flex-1",attrs:{"prop":"name"}},[_c('el-input',{attrs:{"placeholder":"Enter Name"},model:{value:(_vm.inviteForm.name),callback:function ($$v) {_vm.$set(_vm.inviteForm, "name", $$v)},expression:"inviteForm.name"}})],1),_c('el-button',{staticClass:"button-green h-12 ml-3 min-w-10",on:{"click":_vm.onInvite}},[_vm._v(" Invite ")])],1)]),_c('buyer-users-query',{attrs:{"buyer-id":_vm.customer.buyer.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var data = ref.data;
return [_c('h4',{staticClass:"mb-4 mt-8"},[_vm._v(" Current team: "+_vm._s(_vm.customer.buyer.buyer_company)+" ")]),(!isLoading)?_c('div',{staticClass:"overflow-y-auto"},[_c('el-table',{staticClass:"el-table-slim",attrs:{"border":"","data":data.customer.buyer.teamMembers},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty-slot',{attrs:{"is-loading":isLoading,"has-data":!!data.customer.buyer.teamMembers,"empty-message":"This Buyer doesn't have any team members yet"}})]},proxy:true}],null,true)},[_c('el-table-column',{attrs:{"prop":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var user = ref.row;
return [_c('span',[_vm._v(_vm._s(user.name || 'Guest User'))])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"email","label":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var user = ref.row;
return [_c('span',[_vm._v(_vm._s(user.email))])]}}],null,true)})],1)],1):_c('loader-box',{attrs:{"height":"6em"}})]}}],null,false,3206615249)})],1):_c('div',{key:"submit-warning-dialog"},[_c('div',[_vm._v(" This user will recieve an invitation email from flytedesk, as well as future email updates about campaign activity. Please double check that you’ve entered the correct email: ")]),_c('h2',[_vm._v(_vm._s(_vm.inviteForm.email))])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"w-full"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.showSubmitWarningDialog)?_c('div',{key:"submit-warning-footer",staticClass:"flex w-full"},[_c('buyer-team-mutation',{staticClass:"flex w-full",on:{"done":function($event){_vm.showSubmitWarningDialog = false}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isSaving = ref.isSaving;
var inviteUserToBuyerAccount = ref.inviteUserToBuyerAccount;
return [(!isSaving)?[_c('el-button',{staticClass:"button-white",on:{"click":function($event){_vm.showSubmitWarningDialog = false}}},[_vm._v(" Never mind ")]),_c('el-button',{staticClass:"button-white-red",on:{"click":function($event){(_vm.inviteForm.email = '') ||
                        (_vm.showSubmitWarningDialog = false)}}},[_vm._v(" Change Email ")]),_c('el-button',{staticClass:"button-blue",on:{"click":function($event){return inviteUserToBuyerAccount(
                        _vm.customer.buyer.id,
                        _vm.inviteForm.email,
                        _vm.inviteForm.name
                      )}}},[_vm._v(" Yes, send it! ")])]:_c('loading-button')]}}],null,false,3756138420)})],1):_c('div',{key:"invite-buyer-footer",staticClass:"flex w-full"},[_c('el-button',{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")])],1)])],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }