<template>
  <div class="form-toggle">
    <div class="flex justify-between">
      <div class="mb-3">
        <h4>
          <slot>{{ title }}</slot>
        </h4>
        <div v-if="subTitle" class="mt-3">{{ subTitle }}</div>
      </div>

      <div class="text-right">
        <a v-if="!hideClose" class="p-3 -mr-3 -mt-3" @click="toggleEditForm">
          <icon v-if="isEditing" :icon="closeIcon" />
          <tooltip-icon
            v-else
            reference-class="text-blue cursor-pointer hover:text-dark-blue"
            :is-disabled="isDisabled"
            :icon="pencilIcon"
          />
        </a>
      </div>
    </div>
    <transition-group name="fade" class="animate-position">
      <div v-if="!showEditForm" :key="'form-details'">
        <slot name="view" />
      </div>
      <div v-else :key="'form-edit'">
        <div class="form-toggle-edit">
          <slot name="edit" />
        </div>

        <div v-if="showSubmit" class="form-toggle-submit">
          <slot name="submit">
            <el-button
              v-if="!isLoading"
              :disabled="!hasChanges"
              class="button-blue w-full mt-6"
              @click="$emit('submit')"
            >
              Save Changes
            </el-button>
            <loading-button v-else class="w-full mt-8" />
          </slot>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import {
  cancel as closeIcon,
  pencil as pencilIcon
} from '@/vendor/icons/index';

import { LoadingButton } from '@/components/Core/Loading';
import TooltipIcon from '@/components/Core/TooltipIcon';

export default {
  components: {
    LoadingButton,
    TooltipIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      default: ''
    },
    isDisabled: Boolean,
    isLoading: Boolean,
    isEditing: Boolean,
    hasChanges: Boolean,
    showSubmit: Boolean,
    hideClose: Boolean
  },
  data() {
    return {
      showEditForm: this.isEditing,

      // Icons
      closeIcon,
      pencilIcon
    };
  },
  watch: {
    isEditing() {
      this.showEditForm = this.isEditing;
    }
  },
  methods: {
    toggleEditForm() {
      this.showEditForm = this.isDisabled ? false : !this.showEditForm;
      this.$emit('update:is-editing', this.showEditForm);
    }
  }
};
</script>
