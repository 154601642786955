<template>
  <customer-mutation>
    <template v-slot="{ loading, updateCustomer }">
      <custom-el-select
        v-if="$can('pub_manage_customer')"
        :value="customer.billing_preference"
        placeholder="Billing Preference"
        class="w-full"
        select-class="w-full"
        singular
        hide-icons
        :is-loading="loading"
        :options="BillingPreferences"
        @change="updateCustomer(customer, { billing_preference: $event })"
      />
      <div v-else>
        {{ BillingPreferenceLabel[customer.billing_preference] }}
      </div>
    </template>
  </customer-mutation>
</template>

<script>
import { BillingPreferenceLabel, BillingPreferences } from '@/constants';
import { CustomerMutation } from '@/components/Mutations';
import CustomElSelect from '@/components/Custom/CustomElSelect';

export default {
  components: {
    CustomElSelect,
    CustomerMutation
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      BillingPreferences,
      BillingPreferenceLabel
    };
  }
};
</script>
