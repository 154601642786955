<template>
  <customer-mutation @data="afterImport">
    <template v-slot="{ isSaving, importCustomers }">
      <confirm-dialog
        title="Upload Customers CSV File"
        confirm-text="Import Customers"
        confirm-class="button-green"
        width="40em"
        :disabled="!importForm.file"
        :is-saving="isSaving"
        @confirm="importCustomers(importForm.file)"
        @close="$emit('close')"
      >
        <import-customers-form v-model="importForm" />
      </confirm-dialog>
    </template>
  </customer-mutation>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import ImportCustomersForm from '@/components/Supplier/Customers/ImportCustomersForm';
import { CustomerMutation } from '@/components/Mutations';

export default {
  components: {
    ConfirmDialog,
    CustomerMutation,
    ImportCustomersForm
  },

  data() {
    return {
      importForm: {
        file: null
      }
    };
  },

  computed: {
    userEmail() {
      return this.$store.getters['auth/email'];
    }
  },

  methods: {
    afterImport(response) {
      if (response.mutateCustomer.import) {
        this.$message.success(
          `Customer import is in progress. You will be notified by email at ${this.userEmail} when the import has completed.`
        );

        this.$emit('close');
      } else {
        this.$message.error('Customer Import failed. Please try again later');
      }
    }
  }
};
</script>
