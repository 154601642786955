<template>
  <el-form
    ref="userForm"
    label-position="top"
    :disabled="isDisabled || isLoading"
    :rules="rules"
    :model="value"
  >
    <div class="flex flex-row mt-6">
      <custom-el-form-item
        :disabled="isDisabled"
        :is-loading="isLoading"
        label-class="font-semibold"
        class="w-1/2 mr-3"
        label="First Name"
        prop="first_name"
      >
        <loader-box
          v-if="isLoading && loadingBoxes"
          class="w-full"
          :height="2.75"
        />
        <el-input
          v-else
          v-model="model.first_name"
          placeholder="First Name"
          @focus="$emit('focus')"
        />
      </custom-el-form-item>
      <custom-el-form-item
        :disabled="isDisabled"
        :is-loading="isLoading"
        label-class="font-semibold"
        class="w-1/2"
        label="Last Name"
        prop="last_name"
      >
        <loader-box
          v-if="isLoading && loadingBoxes"
          class="w-full"
          :height="2.75"
        />
        <el-input
          v-else
          v-model="model.last_name"
          placeholder="Last Name"
          @focus="$emit('focus')"
        />
      </custom-el-form-item>
    </div>
    <div class="flex flex-row mt-4">
      <custom-el-form-item
        ref="userEmail"
        :disabled="isDisabled"
        :is-loading="isLoading"
        label-class="font-semibold"
        class="w-1/2 mr-3"
        label="Email"
        prop="email"
      >
        <loader-box
          v-if="isLoading && loadingBoxes"
          class="w-full"
          :height="2.75"
        />
        <el-input
          v-else
          v-model="model.email"
          placeholder="Email"
          @focus="$emit('focus')"
        />
      </custom-el-form-item>
      <custom-el-form-item
        :is-loading="isLoading"
        :disabled="isDisabled"
        label-class="font-semibold"
        class="w-1/2"
        label="Phone"
        prop="phone"
      >
        <loader-box
          v-if="isLoading && loadingBoxes"
          class="w-full"
          :height="2.75"
        />
        <el-input
          v-else
          v-model="model.phone"
          v-mask.phone
          placeholder="Phone Number"
          @focus="$emit('focus')"
        />
      </custom-el-form-item>
    </div>
  </el-form>
</template>

<script>
import { validEmail } from '@/utils/filters';

import CustomElFormItem from '@/components/Custom/CustomElFormItem';

export default {
  components: {
    CustomElFormItem
  },
  props: {
    loadingBoxes: Boolean,
    customRules: { type: Object, default: null },
    value: {
      type: Object,
      default: () => ({
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      })
    },
    isDisabled: Boolean,
    isLoading: Boolean
  },
  data() {
    return {
      rules: {
        email: [{ validator: validEmail, trigger: 'blur' }],
        ...this.customRules
      }
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', { ...this.value, ...val });
      }
    }
  },
  methods: {
    clearValidate() {
      this.$refs.userForm.clearValidate();
    },
    resetFields() {
      this.$refs.userForm.resetFields();
    },
    validate(callback) {
      this.$refs.userForm.validate(callback);
    }
  }
};
</script>
