<template>
  <section>
    <supplier-customers-search-tools-query
      @data="searchTools = $event.supplier.customersData.searchTools"
    />

    <invoice-terms-query @data="termOptions = $event" />

    <div class="flex flex-row border-top">
      <div class="flex-shrink-0">
        <collapsible-search-group-sidebar
          v-model="searchInput"
          :search-tools="searchTools || []"
          :is-loading="!searchTools"
          :filter="pager.filter"
          @search="pager = { ...pager, ...$event }"
        />
      </div>
      <div
        class="flex flex-1 flex-col p-8 overflow-x-auto min-w-40 border-left"
      >
        <filter-toolbar
          class="items-end"
          hide-export
          placeholder="Search Companies, Reps, etc."
          :value="pager.filter"
          @input="pager = { ...pager, filter: $event }"
        >
          <!-- TODO: Remove this export component in favor of GraphQL export -->
          <supplier-customers-toolbar :filter="pager.filter" />
        </filter-toolbar>

        <div class="relative">
          <supplier-customers-query :pager="pager" @data="onData">
            <template v-slot="{ isLoading }">
              <el-table
                v-loading="isLoading"
                border
                class="el-table-slim mt-6"
                :data="customers"
              >
                <table-empty-slot
                  slot="empty"
                  :is-loading="isLoading"
                  :has-data="!!customers"
                  error-message="There was a problem loading customers. Please try again later."
                >
                  You do not have any customers yet!
                  <a @click="showCreateCustomerDialog = true">
                    Create a new customer.
                  </a>
                </table-empty-slot>

                <!-- Edit Column -->
                <el-table-column align="center" width="70">
                  <template v-slot="{ row }">
                    <icon
                      class="icon-xl cursor-pointer text-blue hover:text-dark-blue"
                      :icon="editIcon"
                      @click="customerToEdit = row"
                    />
                  </template>
                </el-table-column>

                <!-- Customer Name Column -->
                <el-table-column prop="buyer.buyer_company" label="Company">
                  <template v-slot="{ row }">
                    <div>
                      {{ row.buyer.buyer_company }} ({{ row.buyer.id }})
                    </div>
                  </template>
                </el-table-column>

                <!-- Reps Column -->
                <el-table-column label="Rep">
                  <template v-slot="{ row }">
                    <reps-column
                      :customer="row"
                      :available-reps="availableReps"
                    />
                  </template>
                </el-table-column>

                <!-- Invoice Column -->
                <el-table-column align="center" label="Default Schedule">
                  <template v-slot="{ row }">
                    <customers-billing-preference-column :customer="row" />
                  </template>
                </el-table-column>
                <el-table-column align="center" label="Default Terms">
                  <template v-slot="{ row }">
                    <customers-invoice-terms-column
                      :customer="row"
                      :options="termOptions"
                    />
                  </template>
                </el-table-column>
                <el-table-column align="center" label="Customer Type">
                  <template v-slot="{ row }">
                    <customer-type-column :customer="row" />
                  </template>
                </el-table-column>

                <custom-el-table-column
                  v-if="allowCreditCardPayment"
                  :align="'center'"
                  width="115"
                >
                  <div slot="header" class="whitespace-normal">
                    <div>Require</div>
                    <div>
                      Credit Card
                      <el-tooltip effect="light" class="text-dark-silver">
                        <div slot="content" class="text-base w-100">
                          <div>
                            Indicates customers are only approved to use a
                            credit card to pay for Ad Shop Orders.
                          </div>
                          <div class="mt-3">
                            If not required, they will be able to pay by Invoice
                            allowing you to process their payments manually.
                          </div>
                          <div class="mt-6 font-bold">
                            IMPORTANT: Your customer will still be able to check
                            out in your Ad Shop without a credit card! You will
                            receive an order that requires approval before
                            becoming active. If you reject the order, the buyer
                            then will be required to enter a credit card at that
                            time or cancel the order.
                          </div>
                        </div>
                        <icon :icon="helpIcon" />
                      </el-tooltip>
                    </div>
                  </div>
                  <template v-slot="{ row }">
                    <customers-invoice-column :customer="row" />
                  </template>
                </custom-el-table-column>

                <!-- Tags Column -->
                <el-table-column label="Tags" width="228">
                  <template v-slot="{ row }">
                    <customers-tags-column
                      :all-tags="availableTags"
                      :customer="row"
                    />
                  </template>
                </el-table-column>

                <!-- Action Column -->
                <el-table-column align="center" label="Action" width="100">
                  <template v-slot="{ row }">
                    <customers-actions-column :customer="row" />
                  </template>
                </el-table-column>
              </el-table>

              <pagination
                v-if="paginator && paginator.total > 0"
                class="mt-6"
                :info="paginator"
                :pager.sync="pager"
              />

              <!-- Dialogs -->
              <create-customer-dialog
                v-if="showCreateCustomerDialog"
                @close="showCreateCustomerDialog = false"
              />
              <edit-customer-dialog
                v-if="customerToEdit"
                :customer="customerToEdit"
                @close="customerToEdit = null"
              />
            </template>
          </supplier-customers-query>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { helpCircled as helpIcon, pencil as editIcon } from '@/vendor/icons';

import CollapsibleSearchGroupSidebar from '@/components/Core/CollapsibleSearchGroupSidebar';
import CreateCustomerDialog from '@/components/Supplier/Customers/CreateCustomerDialog';
import CustomElTableColumn from '@/components/Custom/CustomElTableColumn';
import CustomersActionsColumn from './CustomersActionsColumn';
import CustomersBillingPreferenceColumn from './CustomersBillingPreferenceColumn';
import CustomersInvoiceColumn from './CustomersInvoiceColumn';
import CustomersInvoiceTermsColumn from './CustomersInvoiceTermsColumn';
import CustomersTagsColumn from './CustomersTagsColumn';
import CustomerTypeColumn from '@/components/Supplier/Customers/CustomerTypeColumn';
import EditCustomerDialog from './EditCustomerDialog';
import FilterToolbar from '@/components/Core/Table/FilterToolbar';
import Pagination from '@/components/Core/Table/Pagination';
import SupplierCustomersSearchToolsQuery from '@/components/Queries/Supplier/Customer/SupplierCustomersSearchToolsQuery';
import SupplierCustomersToolbar from './SupplierCustomersToolbar';
import RepsColumn from '@/components/Supplier/Common/RepsColumn';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import {
  InvoiceTermsQuery,
  SupplierCustomersQuery
} from '@/components/Queries';

export default {
  components: {
    CollapsibleSearchGroupSidebar,
    CreateCustomerDialog,
    CustomElTableColumn,
    CustomersActionsColumn,
    CustomersBillingPreferenceColumn,
    CustomersInvoiceColumn,
    CustomersInvoiceTermsColumn,
    CustomersTagsColumn,
    CustomerTypeColumn,
    EditCustomerDialog,
    FilterToolbar,
    InvoiceTermsQuery,
    Pagination,
    SupplierCustomersQuery,
    SupplierCustomersSearchToolsQuery,
    SupplierCustomersToolbar,
    RepsColumn,
    TableEmptySlot
  },
  data() {
    return {
      availableReps: [],
      availableTags: [],
      customers: [],
      showCreateCustomerDialog: false,
      customerToEdit: null,
      searchInput: {},
      searchTools: null,
      paginator: null,
      termOptions: null,

      // Icons
      editIcon,
      helpIcon
    };
  },
  computed: {
    pager: {
      get() {
        return this.$store.getters['customers/pager'];
      },
      set(value) {
        this.$store.commit('customers/setPager', value);
      }
    },
    adShop() {
      return this.$store.getters['auth/adShop'];
    },
    allowCreditCardPayment() {
      return this.adShop.allow_credit_card_payment;
    }
  },
  watch: {
    customers(newCustomers) {
      if (this.customerToEdit) {
        this.customerToEdit = newCustomers.find(
          customer => customer.id === this.customerToEdit.id
        );
      }
    }
  },
  methods: {
    onData({ supplier }) {
      this.availableReps = supplier.buyerReps;
      this.availableTags = supplier.customerTags;
      this.customers = supplier.customersData.customers.data;
      this.paginator = supplier.customersData.customers.paginatorInfo;
    }
  }
};
</script>
