<template>
  <section class="page-content">
    <div class="flex justify-between">
      <the-page-header>
        <template slot="title">
          Customers
        </template>
        <template slot="subtitle">
          Customers that have booked through your flytedesk ad shop are listed
          below
        </template>
      </the-page-header>
      <div class="flex mt-6 h-11">
        <el-button
          :disabled="!$can('pub_create_new_customer')"
          class="button-green"
          @click="showCreateCustomerDialog = true"
        >
          Create New Customer
        </el-button>
        <div class="ml-5">
          <el-button
            v-if="$feature('import-customers')"
            class="button-blue"
            title="Import Customers from a CSV file"
            @click="isImporting = true"
          >
            <icon
              :icon="uploadIcon"
              class="text-xl"
              :class="{ 'animated loop tada': isImporting }"
            />
          </el-button>
        </div>
      </div>
    </div>

    <supplier-customers-table />

    <create-customer-dialog
      v-if="showCreateCustomerDialog"
      @close="showCreateCustomerDialog = false"
    />

    <import-customers-dialog v-if="isImporting" @close="isImporting = false" />
  </section>
</template>

<script>
import ImportCustomersDialog from '@/components/Supplier/Customers/ImportCustomersDialog';
import SupplierCustomersTable from '@/components/Supplier/Customers/SupplierCustomersTable';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';
import { upload as uploadIcon } from '@/vendor/icons';
import CreateCustomerDialog from '@/components/Supplier/Customers/CreateCustomerDialog';

export default {
  components: {
    CreateCustomerDialog,
    ImportCustomersDialog,
    SupplierCustomersTable,
    ThePageHeader
  },

  data() {
    return {
      showCreateCustomerDialog: false,
      isImporting: false,

      // Icons
      uploadIcon
    };
  }
};
</script>
