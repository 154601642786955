<template>
  <section>
    <el-popover placement="bottom-end" width="180">
      <a slot="reference" class="px-4 py-2">
        <icon class="icon-xl text-blue cursor-pointer" :icon="ellipsisIcon" />
      </a>
      <el-button
        v-if="$can('pub_place_order_for_buyer')"
        class="button-plain hover:text-blue"
        plain
        @click="placeOrder"
      >
        Place Order
      </el-button>
      <el-button
        v-if="$can('pub_invite_buyer')"
        class="button-plain hover:text-blue"
        plain
        @click="inviteDialogVisible = true"
      >
        Invite Buyer
      </el-button>
      <el-button
        v-if="$can('pub_edit_company_info')"
        class="button-plain hover:text-blue"
        plain
        @click="editDialogVisible = true"
      >
        Edit Customer

        <edit-customer-dialog
          v-if="editDialogVisible"
          :customer="customer"
          @close="editDialogVisible = false"
        />
      </el-button>
      <el-button
        v-if="$can('pub_edit_company_info')"
        class="button-plain hover:text-blue"
        plain
        @click="removeDialogVisible = true"
      >
        Remove Customer
      </el-button>
    </el-popover>
    <remove-customer-dialog
      :customer="customer"
      :visible="removeDialogVisible"
      @close="removeDialogVisible = false"
    />
    <invite-customer-dialogs
      v-if="inviteDialogVisible"
      :customer="customer"
      @close="inviteDialogVisible = false"
    />
  </section>
</template>

<script>
import { ellipsisV as ellipsisIcon } from '@/vendor/icons';
import InviteCustomerDialogs from './InviteCustomerDialogs';
import RemoveCustomerDialog from './RemoveCustomerDialog';
import EditCustomerDialog from './EditCustomerDialog';

export default {
  components: {
    EditCustomerDialog,
    InviteCustomerDialogs,
    RemoveCustomerDialog
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      inviteDialogVisible: false,
      removeDialogVisible: false,
      editDialogVisible: false,

      // Icons
      ellipsisIcon
    };
  },
  methods: {
    placeOrder() {
      this.$store.commit('adShop/setTakeoverBuyerId', this.customer.buyer.id);

      this.$router.push({
        name: 'adShop',
        params: { shopSlug: this.$store.getters['auth/adShopSlug'] }
      });
    }
  }
};
</script>
