<template>
  <div>
    <customer-rep-mutation :show-error-message="false">
      <template
        slot-scope="{
          isSaving: isSavingCustomerRep,
          addCustomerRep,
          removeCustomerRep
        }"
      >
        <order-rep-mutation :show-error-message="false">
          <template
            slot-scope="{
              isSaving: isSavingOrderRep,
              addOrderRep,
              removeOrderRep
            }"
          >
            <div>
              <div class="flex justify-between">
                <div v-if="reps.length > 0">
                  <ul class="rep-list">
                    <li v-for="rep in reps" :key="`rep-${rep.id}`">
                      <span v-if="rep.name">{{ rep.name }}</span>
                      <span v-else>{{ rep.email }}</span>
                    </li>
                  </ul>
                </div>
                <div>
                  <tooltip-icon
                    reference-class="text-blue cursor-pointer hover:text-dark-blue"
                    :is-disabled="!$can('pub_assign_or_edit_reps')"
                    :icon="pencilIcon"
                    @click="showRepDialog = true"
                  >
                    <template #after>
                      <span>
                        {{ reps.length > 0 ? 'Edit' : 'Assign Rep' }}
                      </span>
                    </template>
                  </tooltip-icon>
                </div>
              </div>

              <confirm-dialog
                v-if="showRepDialog"
                id="rep-picker-dialog"
                :title="dialogTitle"
                :disabled="true"
                cancel-text="Close"
                width="40%"
                @close="showRepDialog = false"
              >
                <rep-picker
                  :available-reps="availableReps"
                  :current-reps="reps"
                  :is-saving-id="isSavingId"
                  @add="onAdd($event, addOrderRep, addCustomerRep)"
                  @remove="onRemove($event, removeOrderRep, removeCustomerRep)"
                />
              </confirm-dialog>
            </div>
          </template>
        </order-rep-mutation>
      </template>
    </customer-rep-mutation>
  </div>
</template>
<script>
import RepPicker from '@/components/Supplier/Common/RepPicker';
import { CustomerRepMutation, OrderRepMutation } from '@/components/Mutations';
import TooltipIcon from '@/components/Core/TooltipIcon';

import { pencil as pencilIcon } from '@/vendor/icons/index';
import ConfirmDialog from '@/components/Core/ConfirmDialog';

export default {
  components: {
    ConfirmDialog,
    RepPicker,
    CustomerRepMutation,
    OrderRepMutation,
    TooltipIcon
  },

  props: {
    customer: {
      type: Object,
      default: null
    },
    order: {
      type: Object,
      default: null
    },
    availableReps: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      // State
      showRepDialog: false,
      isSavingId: null,

      // Icons
      pencilIcon
    };
  },

  computed: {
    supplierId() {
      return this.$store.getters['auth/supplierId'];
    },
    reps() {
      if (this.order) {
        return this.order.reps;
      } else if (this.customer) {
        if (this.customer.buyerReps) {
          return this.customer.buyerReps;
        } else {
          return this.customer.reps;
        }
      } else {
        throw Error(
          'Order or Customer is required for the RepsColumn component'
        );
      }
    },
    dialogTitle() {
      if (this.order) {
        return 'Reps for Order #' + this.order.ref;
      } else {
        return this.customer.buyer.buyer_company + ' Account Reps';
      }
    }
  },
  methods: {
    async onAdd(repID, addOrderRep, addCustomerRep) {
      if (this.order) {
        await addOrderRep(this.order, repID);
      } else {
        await addCustomerRep(this.customer, repID);
      }
    },
    async onRemove(repID, removeOrderRep, removeCustomerRep) {
      this.isSavingId = repID;

      if (this.order) {
        await removeOrderRep(this.order.id, repID);
      } else {
        await removeCustomerRep(this.customer.id, repID);
      }

      this.isSavingId = null;
    }
  }
};
</script>
